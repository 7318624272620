import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import GlobalWrapper from 'components/global-wrapper'
import Header from 'components/article/header'
import CoverImage from 'components/article/coverimage'
import ContentWrapper from 'components/article/contentwrapper'
import Footer from 'components/footer'


import Img1 from 'img/articles/project1/img1.jpg'


const Section = styled.section`
  margin: 64px 0;
  ${media.sm`
    margin: 0;
  `}
`

const Project1 = () => {
  return (
    <GlobalWrapper>
      <Header title="Dave & Busters X5" />
      <CoverImage src={Img1} focusX={'28%'} focusY={'70%'} />
      <Section>
        <ContentWrapper>
          <h2>Dave & Buster's X5</h2>

          <p>Introducing the Dave and Buster's X5 Virtual Reality Simulator, an awe-inspiring attraction that brings the thrilling world of Jurassic World to life. With expertise in Unity software development, electrical engineering, project management, and PCB design, I humbly share my contributions to this groundbreaking project.</p>
          
          <p>With meticulous attention to detail and a deep passion for cutting-edge technology, I have crafted the software that powers the X5 Virtual Reality Simulator. Leveraging the power of Unity, the software seamlessly combines captivating visuals, realistic interactions, and heart-pounding action, delivering an unparalleled adventure for visitors. Every element of the software has been thoughtfully designed to create an immersive and unforgettable experience.</p>

          <p>As an electrical engineer, I have been involved in every aspect of the project, including designing the PCB that powers the entire system. From prototyping the firmware in assembly and C languages to performing production debugging, I have ensured the smooth operation and reliability of all units. My dedication to precision and attention to detail have been instrumental in achieving a seamless integration between hardware and software, providing visitors with a truly immersive experience.</p>

          <p>In addition to my technical roles, I have taken on the responsibilities of project management, coordinating the efforts of the development team. With a humble approach and collaborative mindset, I have facilitated effective communication, resource allocation, and adherence to project timelines. My project management skills have played a vital role in driving the project to its successful completion, resulting in an exceptional attraction that captivates audiences.</p>

          <p>The Dave and Buster's X5 Virtual Reality Simulator stands as a testament to my passion for Unity software development, electrical engineering, project management, and PCB design. Through humble dedication and meticulous attention to detail, I have created an immersive and captivating experience that transports visitors to the awe-inspiring world of Jurassic World. Explore the website to learn more about my contributions and the thrilling adventure that awaits at this extraordinary attraction.</p>
          


        </ContentWrapper>

        <ContentWrapper>
          <h2>Game Development</h2>

          <p>
          Creating the Dave and Buster's X5 Virtual Reality Simulator was an exciting endeavor that involved harnessing the power of Unity to bring the thrilling world of Jurassic World to life. With a humble approach and a deep passion for game development, I embarked on the journey of writing the game in Unity.
          </p>
          Throughout the development process, I meticulously crafted the game mechanics, visuals, and interactive elements to provide an immersive and captivating experience for players. Leveraging the versatility of the Unity engine, I seamlessly integrated stunning visual effects, realistic animations, and engaging gameplay elements, all while maintaining a seamless and optimized performance.
          <p> 
          Unity's intuitive and powerful development environment allowed me to bring my creative vision to fruition. From designing the lush environments of Jurassic World to implementing dynamic character movements and thrilling dinosaur encounters, every aspect of the game was carefully crafted to evoke a sense of wonder and excitement.
          </p><p>
          I leveraged Unity's extensive library of tools and resources to enhance the game's functionality and optimize its performance. Through thoughtful scripting, I ensured smooth gameplay mechanics, intuitive controls, and seamless transitions between scenes. I also made use of Unity's physics engine to create lifelike interactions, allowing players to feel the weight of their actions as they navigate the immersive world.
          </p><p>
          Iterative development and rigorous testing were essential parts of the process. I continuously fine-tuned and optimized the game to deliver a seamless and immersive experience. Through thorough debugging and playtesting, I addressed any issues and implemented necessary improvements to create a polished and enjoyable final product.
          </p><p>
          Writing the game in Unity was a humbling and rewarding experience. It allowed me to merge my passion for game development with my technical expertise, resulting in a captivating and immersive experience for players. The Dave and Buster's X5 Virtual Reality Simulator stands as a testament to the power of Unity and the dedication poured into crafting an unforgettable gaming adventure.
          </p>
        </ContentWrapper>

        <ContentWrapper>
        <h2>Firmware and Cloud Server</h2>
        <p>
          In addition to writing the game in Unity, I also undertook the task of prototyping the firmware in assembly and C languages, as well as developing the Arduino-based system for the Dave and Buster's X5 Virtual Reality Simulator. With a humble approach and a deep understanding of low-level programming, I embarked on this critical aspect of the project.
        </p>
        <p>
          Prototyping the firmware in assembly and C languages allowed me to optimize the performance and responsiveness of the simulator's hardware components. I carefully coded the firmware to ensure smooth communication between the various subsystems, such as motion platforms and sensory devices, guaranteeing a seamless and immersive experience for users. By leveraging the power of assembly programming, I achieved efficient execution of critical functions, ensuring the system's reliability and real-time responsiveness.
        </p>
        <p>
          Furthermore, I utilized Arduino microcontrollers to create a robust and flexible control system for the simulator. With meticulous attention to detail, I designed and implemented the Arduino-based circuitry to interface with various sensors and actuators, allowing precise control over the simulator's movements and interactions. The prototyping phase enabled me to iterate on the design, fine-tune performance, and ensure compatibility with the overall system architecture.
        </p>
        <p>
          Additionally, I established an AWS server infrastructure for analytics and maintenance data. Leveraging the power of cloud computing, I designed a scalable and secure server system that collects and analyzes valuable data for maintenance, performance optimization, and user behavior insights. Through this server infrastructure, I enabled the collection of important analytics, which in turn helps ensure the simulator's smooth operation, identify potential issues, and make data-driven improvements.
        </p>
        <p>
          The combination of prototyping the firmware in assembly and C, along with the Arduino-based control system and AWS server infrastructure, allowed for a comprehensive and robust solution. This holistic approach ensures the Dave and Buster's X5 Virtual Reality Simulator delivers a seamless and immersive experience while also providing valuable data for maintenance and optimization.
        </p>
        <p>
          Embracing the challenges of low-level programming, hardware prototyping, and cloud infrastructure, I navigated these aspects of the project to deliver a cohesive and high-quality experience. The Dave and Buster's X5 Virtual Reality Simulator stands as a testament to the harmonious integration of software, hardware, and data analytics, resulting in an exceptional and unforgettable attraction.</p>
        

        </ContentWrapper>
      </Section>
      <Footer />
    </GlobalWrapper>
  )
}

export default Project1
